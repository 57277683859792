export const SocialData=[
    {   id: 1,
        platform:"Linkedin",
        icon: require("../../assets/icons/linkedin.png"),
        link:"https://www.linkedin.com/in/anamariapedroza/",
    },
    {   id:2,
        platform:"Github",
        icon: require("../../assets/icons/github.png"),
        link:"https://github.com/anapedroza29",
    },
    {   id:3,
        platform:"Gitlab",
        icon: require("../../assets/icons/gitlab.png"),
        link:"https://gitlab.com/anapedroza29",
    },
    {   id:4,
        platform:"Envelop",
        icon: require("../../assets/icons/envelope.png"),
        link:"mailto:anapedroza29@gmail.com",
    },
    
];